<template>
  <div class="caseIndex">
    <div class="top">
      <div class="top-img">
        <div v-if="tab1 == 1">
          <el-carousel trigger="click" height="150px">
            <el-carousel-item v-for="item in 4" :key="item">
              <h3 class="small">{{ item }}</h3>
            </el-carousel-item>
          </el-carousel>
        </div>
        <div v-if="tab1 == 2">全景图</div>
        <div class="topImgBtn">
          <span :class="tab1 == 1 ? 'spWhite' : 'sp'" @click="tab1 = 1">图片</span>
          <span :class="tab1 == 2 ? 'spWhite' : 'sp'" @click="tab1 = 2">全景</span>
        </div>
      </div>
      <div class="topImgData">
        <div class="topImgDataOne">
          <h1 class="one">迷迭香--现代简约</h1>
          <span class="two">中式</span>
          <p class="three">
            <span class="sp">曝光：7058</span>
            <span class="sp">浏览：7058</span>
            <span class="sp">收藏：7058</span>
          </p>
          <span class="four">查看数据</span>
        </div>
        <div class="topImgDataTwo">
          <el-tag type="info" size="small">标签三</el-tag>
          <el-tag type="info" size="small">标签三</el-tag>
          <el-tag type="info" size="small">标签三</el-tag>
        </div>
        <div class="topImgDataThree">
          <p class="sp">
            Lorem ipsum dolor sit amet, consectetur adipiscing elit. Aenean euismod bibendum
            laoreet. Proin gravida dolor sit amet lacus accumsan et viverra justo commodo. Proin
            sodales pulvinar tempor. Cum sociis natoque penatibus et magnis dis parturient montes,
            nascetur ridiculus mus. Nam fermentum, nulla luctus pharetra vulputate, felis tellus
            mollis orci, sed rhoncus sapien nunc eget odio. Lorem ipsum dolor sit amet, consectetur
            adipiscing elit. Aenean euismod bibendum laoreet. Proin gravida dolor sit amet lacus
            accumsan et viverra justo commodo. Proin sodales
          </p>
        </div>
      </div>
    </div>
    <div class="cneterInfo">
      <h4 class="h4">基础信息</h4>
      <div style="display: flex">
        <div class="cneterInfoData">
          <p class="p">
            创建人：
            <span class="btn">蜀将</span>
          </p>
          <p class="p">
            创建时间：
            <span>2022年7月20日15:42:36</span>
          </p>
          <p class="p">
            最后修改人：
            <span class="btn">蜀将</span>
          </p>
          <p class="p">
            最后修改时间：
            <span>2022年7月20日15:42:36</span>
          </p>
        </div>
        <div class="cneterInfoData">
          <p class="p">
            排序规则：
            <span>默认</span>
          </p>
          <p class="p">
            小区：
            <span>上海金盛湾</span>
          </p>
          <p class="p">
            面积：
            <span>291㎡</span>
          </p>
          <p class="p">
            户型：
            <span>六室两厅一厨三卫</span>
          </p>
        </div>
        <div class="cneterInfoData">
          <p class="p">
            装饰类型：
            <span>工装</span>
          </p>
          <p class="p">
            完工时间：
            <span>2022年7月20日16:17:13</span>
          </p>
        </div>
      </div>
    </div>
    <div class="bottomFramework">
      <div class="title">
        <h4>关联构件</h4>
        <span class="sp">共关联78个构件</span>
      </div>
      <div class="frameworkData">
        <el-card shadow="hover" class="frameworkData-one" v-for="i in 10" :key="i">
          <div>
            <div class="frameworkData-one-Img">
              <img style="width: 100%; height: 100%; object-fit: cover" src="" alt="" />
            </div>
            <p class="p">圣伯雅-金盛系列座椅金盛系列座椅金盛系列座椅</p>
          </div>
        </el-card>
      </div>
    </div>
    <div class="comment">
      <div class="title">
        <h4>案例评论</h4>
        <span class="sp">共702条评论</span>
      </div>
      <div class="commentList">
        <div class="commentListTop">
          <div class="topImg">
            <div class="img">
              <img style="width: 100%; height: 100%; object-fit: cover" src="" alt="" />
            </div>
            <div class="name">
              <h4 class="h4">彰显界</h4>
              <span class="sp">2022年7月20日16:21:26F</span>
            </div>
          </div>
          <div class="zan">
            <span class="iconfont icon-dianzan"></span>
            <span>12</span>
            个
            <!-- <span class="iconfont icon-dianzan1"></span><span>12</span>个 -->
          </div>
        </div>
        <div class="size">
          <p>
            这样来看，结合马斯克前前后后的布局和表态，包括SpaceX、自动驾驶、脑机接口等等，马院士的永生蓝图，还包括了火星移民计划：用脑机接口技术将人类意识连接到机器人身上，然后通过SpaceX的可回收火箭将机器人送到火星。既然火星环境恶劣不适合人类生存，改造起来成本又太高，这种方法无疑是最具可操作性的，毕竟相比于人类，机器人在火星上的适应性更强。至于肉体，就沉睡吧，等到肉体消亡的前一刻，直接将意识上传到到机器人身上就行。这样一来，永生的愿望实现了，移民火星的目标也达到了
          </p>
        </div>
      </div>
      <div class="add">
        <p>
          <i class="el-icon-arrow-down"></i>
          展开更多
        </p>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  // 案例库详情
  name: 'caseIndex',
  // 组件参数 接收来自父组件的数据
  props: {
    id: {
      type: Number,
      require: true,
    },
  },
  // import引入的组件需要注入到对象中才能使用
  components: {},
  data() {
    // 这里存放数据
    return {
      tab1: 1,
      list: [], //案例详情
    };
  },
  // 监听属性 类似于data概念
  computed: {},
  // 监控data中的数据变化
  watch: {},
  // 方法集合
  methods: {},
  // 生命周期 - 创建完成（可以访问当前this实例）
  created() {},
  // 生命周期 - 挂载完成（可以访问DOM元素）
  mounted() {},
  // 这里存放数据
  beforeCreate() {},
  // 生命周期 - 挂载之前
  beforeMount() {},
  // 生命周期 - 更新之前
  beforeUpdate() {},
  // 生命周期 - 更新之后
  updated() {},
  // 生命周期 - 销毁之前
  beforeDestroy() {},
  // 生命周期 - 销毁完成
  destroyed() {},
  // 如果页面有keep-alive缓存功能，这个函数会触发
  activated() {},
};
</script>
<style lang="scss" scoped>
// @import url(); 引入公共css类
.caseIndex {
  background-color: white;
  margin: 20px;
  padding: 30px 20px;

  .top {
    display: flex;

    .top-img {
      border: 1px solid #000;
      display: inline-block;
      width: 400px;
      height: 300px;
      position: relative;
      border-radius: 15px;
      margin-right: 25px;

      .topImgBtn {
        position: absolute;
        bottom: 20px;
        left: 50%;
        transform: translateX(-50%);
        font-size: 14px;
        background-color: rgba(0, 0, 0, 0.7);
        padding: 5px 20px;
        border-radius: 20px;

        .sp {
          color: rgba(255, 255, 255, 0.5);
          margin: 0 5px;
        }

        .sp:hover {
          color: white;
        }

        .spWhite {
          color: white;
          margin: 0 5px;
        }
      }
    }

    .topImgData {
      max-width: calc(100% - 450px);

      .topImgDataOne {
        display: flex;
        align-items: center;

        .one {
          margin: 5px 0;
          font-size: 25px;
        }

        .two {
          background-color: #4f4f4f;
          color: white;
          margin: 0 20px;
          padding: 5px 15px;
          border-radius: 20px;
          font-size: 14px;
        }

        .three {
          margin: 10px 0;
          font-size: 13px;
          color: #bbb;

          .sp {
            margin: 10px;
          }
        }

        .four {
          color: #047eff;
          font-size: 13px;
          margin-left: 10px;
        }
      }

      .topImgDataTwo {
        margin: 10px 0;

        .el-tag {
          margin-right: 10px;
        }
      }

      .topImgDataThree {
        font-size: 14px;
      }
    }
  }

  .cneterInfo {
    background-color: #f1f1f1;
    width: 100%;
    margin: 20px 0;
    border-radius: 10px;
    padding: 20px;
    box-sizing: border-box;

    .h4 {
      margin: 0;
    }

    .cneterInfoData {
      font-size: 13px;
      margin-right: 80px;

      .p {
        margin: 30px 0;

        .btn {
          background-color: #c6c6c6;
          display: inline-block;
          padding: 2px 15px;
          border-radius: 5px;
        }
      }
    }
  }

  .bottomFramework {
    .title {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #bbb;

      .sp {
        font-size: 13px;
        margin-left: 15px;
        color: #bbb;
      }
    }

    .frameworkData {
      margin: 20px 0;

      ::v-deep .el-card__body {
        padding: 0;
      }

      .frameworkData-one {
        display: inline-block;
        margin-right: 15px;
        margin-bottom: 15px;

        .frameworkData-one-Img {
          width: 210px;
          height: 210px;
        }

        .p {
          width: 190px;
          margin: 20px 10px;
          font-size: 13px;
          overflow: hidden;
          text-overflow: ellipsis;
          white-space: nowrap;
        }
      }
    }
  }

  .comment {
    .title {
      display: flex;
      align-items: center;
      border-bottom: 1px solid #bbb;

      .sp {
        font-size: 13px;
        margin-left: 15px;
        color: #bbb;
      }
    }

    .commentList {
      border-bottom: 1px solid #bbb;

      .commentListTop {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin: 15px 0;

        .topImg {
          display: flex;
          align-items: center;

          .img {
            width: 40px;
            height: 40px;
            border-radius: 50px;
            overflow: hidden;
            margin-right: 15px;
          }

          .name {
            .h4 {
              margin: 0;
            }

            .sp {
              font-size: 13px;
              color: #bbb;
            }
          }
        }

        .zan {
          font-size: 13px;
        }
      }

      .size {
        font-size: 14px;
      }
    }

    .add {
      text-align: center;
      cursor: pointer;
    }
  }
}
</style>
